import React, { useState, useEffect } from "react";

// third-party
import { IntlProvider, MessageFormatElement } from "react-intl";
import useConfig from "hooks/useConfig";

// load locales files
const loadLocaleData = (locale: string) => {
  switch (locale.substring(0, 2).toLowerCase()) {
    case "en":
      console.log("en");
      return import("utils/locales/en.json");
    default:
      console.log("es");
      return import("utils/locales/es.json");
  }
};

// ==============================|| LOCALIZATION ||============================== //

interface LocalsProps {
  children: React.ReactNode;
}

const Locales = ({ children }: LocalsProps) => {
  const locale = navigator.language;
  const [messages, setMessages] = useState<
    Record<string, string> | Record<string, MessageFormatElement[]> | undefined
  >();
  useEffect(() => {
    loadLocaleData(locale).then(
      (d: {
        default:
          | Record<string, string>
          | Record<string, MessageFormatElement[]>
          | undefined;
      }) => {
        setMessages(d.default);
      }
    );
  }, [locale]);
  console.log("locales " + navigator.language);
  return (
    <>
      {messages && (
        <IntlProvider
          locale={navigator.language}
          defaultLocale={navigator.language}
          messages={messages}
        >
          {children}
        </IntlProvider>
      )}
    </>
  );
};

export default Locales;
