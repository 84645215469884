import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import { SessionAuth } from "supertokens-auth-react/recipe/session";

// sample page routing
const DashboardPage = Loadable(lazy(() => import("views/pages/dashboard")));
const Dash2 = Loadable(lazy(() => import("views/pages/d2")));
const InvoicePage = Loadable(lazy(() => import("views/pages/invoice")));
const DetailsPage = Loadable(lazy(() => import("views/pages/details")));
const SettingsPage = Loadable(lazy(() => import("views/pages/settings")));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <SessionAuth>
      <MainLayout />
    </SessionAuth>
  ),
  children: [
    {
      path: "/",
      element: <DashboardPage />,
    },
    {
      path: "/invoice",
      element: <InvoicePage />,
    },
    {
      path: "/details",
      element: <DetailsPage />,
    },
    {
      path: "/settings",
      element: <SettingsPage />,
    },
  ],
};

export default MainRoutes;
