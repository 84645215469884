// project import
import other from "./other";
import samplePage from "./sample-page";

// types
import { NavItemType } from "types";

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [samplePage],
};

export default menuItems;
