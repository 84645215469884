import { RouterProvider } from "react-router-dom";
// routing
import router from "routes";
// project imports
import Locales from "ui-component/Locales";
import NavigationScroll from "layout/NavigationScroll";
// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from "ui-component/extended/Snackbar";
import ThemeCustomization from "themes";
import Notistack from "ui-component/third-party/Notistack";

import { SuperTokensWrapper } from "supertokens-auth-react";

const App = () => (
  <SuperTokensWrapper>
    <ThemeCustomization>
      <Locales>
        <NavigationScroll>
          <>
            <Notistack>
              <RouterProvider router={router} />
              <Snackbar />
            </Notistack>
          </>
        </NavigationScroll>
      </Locales>
    </ThemeCustomization>
  </SuperTokensWrapper>
);

export default App;
