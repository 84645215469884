import { createBrowserRouter } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";

import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";

import { PasswordlessPreBuiltUI } from "supertokens-auth-react/recipe/passwordless/prebuiltui";
import * as reactRouterDom from "react-router-dom";

import SuperTokens from "supertokens-auth-react";
import Passwordless from "supertokens-auth-react/recipe/passwordless";
import Session from "supertokens-auth-react/recipe/session";
import { Login } from "@mui/icons-material";

// ==============================|| APP ||============================== //

SuperTokens.init({
  appInfo: {
    apiDomain: process.env.REACT_APP_API_DOMAIN || "http://localhost:5000",
    apiBasePath: "/auth",
    appName: "Frust",
    websiteDomain:
      process.env.REACT_APP_WEBSITE_DOMAIN || "http://localhost:3000",
    websiteBasePath: "/auth",
    apiGatewayPath: "/",
  },
  disableAuthRoute: true,
  recipeList: [
    Passwordless.init({
      contactMethod: "EMAIL",
    }),
    Session.init({ tokenTransferMethod: "header" }),
  ],
  getRedirectionURL: async (context) => {
    if (context.action === "SUCCESS" && context.newSessionCreated) {
      return "/"; // defaults to "/"
    } else if (context.action === "TO_AUTH") {
      // The user will be taken to this path when they need to login.
      return "/login"; // return the path where you are rendering the Auth UI
    }
  },
  style: `
        [data-supertokens~=superTokensBranding] {
            display: none;
        }
        [data-supertokens~=container] {
          --palette-primary: 228, 81, 241;
          border-radius: 0px;
          border: 2px solid #000000;
          
        }
        [data-supertokens~=headerTitle] {
          padding-left: 20px;
          padding-right: 20px;
          font-family: 'Hatch Sans';
          font-weight: 100;
        }
        [data-supertokens~=headerSubtitle][data-supertokens~=secondaryText] {
          font-family: 'Hatch Sans';
          color: #000000;
        }

        [data-supertokens~="secondaryText"] strong {
                  font-family: 'Hatch Sans';
          color: #000000;
          }
        
        #supertokens-root {
        background-color: #F8ABFC;
        }
        [data-supertokens~=button] {
          background-color: #000000;
          margin-left: 50px;
          margin-right: 50px;
          width: calc(100% - 100px);
          border-color: #000000;
          font-family: 'Hatch Sans';
          font-size: 18px;
          
        }
        [data-supertokens~=inputWrapper] {
          border: 2px solid #000000;
        }
          [data-supertokens~="inputWrapper"]:focus-within {
 border: 2px solid #000000;
    box-shadow: none;
    outline: none;
    border-radius: 0px;
}
    [data-supertokens~=resendCodeBtn] {
     font-family: 'Hatch Sans';
    color: #E451F1;
    }
    [data-supertokens~=generalSuccess] {
     font-family: 'Hatch Sans';
     background-color: #F8ABFC;
     color: #FFFFFF;
    }
    [data-supertokens~=label] {
    font-family: 'Hatch Sans';
}
    [data-supertokens~="secondaryLinkWithLeftArrow"] {
     font-family: 'Hatch Sans';
     color: #000000;
     padding-top: 12px;
}
          `,
  languageTranslations: {
    // This object contains all translation related options
    translations: {
      en: {
        PWLESS_LINK_CLICKED_CONTINUE_HEADER: "¡Hola! Inicia tu sesión",
        PWLESS_USER_INPUT_CODE_HEADER_TITLE: "¡Hola! Inicia tu sesión",
      },
      es: {
        GENERAL_ERROR_EMAIL_UNDEFINED:
          "Por favor, establezca su correo electrónico",
        GENERAL_ERROR_EMAIL_NON_STRING:
          "El correo electrónico debe ser de tipo texto",
        GENERAL_ERROR_EMAIL_INVALID: "El correo electrónico no es válido",

        GENERAL_ERROR_PHONE_UNDEFINED:
          "Por favor, establezca su número de teléfono",
        GENERAL_ERROR_PHONE_NON_STRING:
          "El número de teléfono debe ser de tipo texto",
        GENERAL_ERROR_PHONE_INVALID: "El número de teléfono no es válido",

        GENERAL_ERROR_OTP_UNDEFINED: "Por favor, ingrese su OTP",
        GENERAL_ERROR_OTP_INVALID: "OTP no válido",
        GENERAL_ERROR_OTP_EXPIRED: "OTP expirado.",
        GENERAL_ERROR_OTP_NON_STRING: "OTP debe ser de tipo texto",
        GENERAL_ERROR_OTP_EMPTY: "OTP no puede estar vacío",

        ERROR_SIGN_IN_UP_LINK:
          "Enlace mágico inválido. Por favor, inténtelo de nuevo.",
        ERROR_SIGN_IN_UP_RESEND_RESTART_FLOW:
          "El inicio de sesión ha caducado. Por favor, inténtelo de nuevo.",
        ERROR_SIGN_IN_UP_CODE_CONSUME_RESTART_FLOW:
          "Inicio de sesión no exitoso. Por favor, inténtelo de nuevo.",

        PWLESS_SIGN_IN_UP_EMAIL_LABEL: "Ingresa tu correo electrónico",
        PWLESS_SIGN_IN_UP_PHONE_LABEL: "Número de teléfono",
        PWLESS_SIGN_IN_UP_SWITCH_TO_PHONE: "Usar número de teléfono",
        PWLESS_SIGN_IN_UP_SWITCH_TO_EMAIL: "Usar correo electrónico",
        PWLESS_SIGN_IN_UP_CONTINUE_BUTTON: "Continuar",
        PWLESS_COMBO_CONTINUE_WITH_PASSWORDLESS_LINK:
          "Continuar sin contraseña",
        PWLESS_COMBO_CONTINUE_WITH_PASSWORDLESS_BUTTON:
          "CONTINUAR SIN CONTRASEÑA",

        PWLESS_COMBO_PASSWORD_LABEL: "Contraseña",
        PWLESS_COMBO_FORGOT_PW_LINK: "¿Olvidaste tu contraseña?",

        PWLESS_LINK_SENT_RESEND_SUCCESS: "Enlace reenviado",
        PWLESS_LINK_SENT_RESEND_TITLE: "¡Enlace enviado!",
        PWLESS_LINK_SENT_RESEND_DESC_START_EMAIL: "Hemos enviado un enlace a ",
        PWLESS_LINK_SENT_RESEND_DESC_START_PHONE:
          "Hemos enviado un enlace a tu número de teléfono ",
        PWLESS_LINK_SENT_RESEND_DESC_END_EMAIL:
          ". Haz clic en el enlace para iniciar sesión o registrarte",
        PWLESS_LINK_SENT_RESEND_DESC_END_PHONE: "",

        PWLESS_SIGN_IN_UP_CHANGE_CONTACT_INFO_EMAIL:
          "Cambiar correo electrónico",
        PWLESS_SIGN_IN_UP_CHANGE_CONTACT_INFO_PHONE:
          "Cambiar número de teléfono",

        PWLESS_LINK_CLICKED_CONTINUE_HEADER: "¡Hola! Inicia tu sesión",
        PWLESS_LINK_CLICKED_CONTINUE_DESC:
          "Haz clic en el botón de abajo para iniciar sesión en este dispositivo",
        PWLESS_LINK_CLICKED_CONTINUE_BUTTON: "CONTINUAR",

        PWLESS_RESEND_SUCCESS_EMAIL: "Correo electrónico reenviado",
        PWLESS_RESEND_SUCCESS_PHONE: "SMS reenviado",

        PWLESS_RESEND_BTN_DISABLED_START: "Reenviar en ",
        PWLESS_RESEND_BTN_DISABLED_END: "",
        PWLESS_RESEND_BTN_EMAIL: "Reenviar correo electrónico",
        PWLESS_RESEND_BTN_PHONE: "Reenviar SMS",

        PWLESS_USER_INPUT_CODE_HEADER_TITLE: "¡Hola! Inicia tu sesión",
        PWLESS_USER_INPUT_CODE_HEADER_SUBTITLE:
          "Ingresa el código que enviamos a",
        PWLESS_USER_INPUT_CODE_HEADER_SUBTITLE_LINK:
          "Se le ha enviado un OTP y un enlace mágico a",
        PWLESS_USER_INPUT_CODE_INPUT_LABEL: "Código",

        PWLESS_MFA_HEADER_TITLE_PHONE: "OTP basado en SMS",
        PWLESS_MFA_HEADER_TITLE_EMAIL: "OTP basado en correo electrónico",
        PWLESS_MFA_FOOTER_LOGOUT: "Cerrar sesión",
        BRANDING_POWERED_BY_END: " :)",

        AUTH_PAGE_HEADER_TITLE_SIGN_IN_AND_UP: "¡Hola! Inicia tu sesión",
        AUTH_PAGE_HEADER_TITLE_SIGN_IN: "Iniciar sesión",
        AUTH_PAGE_HEADER_TITLE_SIGN_UP: "Registrarse",

        AUTH_PAGE_HEADER_SUBTITLE_SIGN_IN_START:
          "¿Todavía no estás registrado?",
        AUTH_PAGE_HEADER_SUBTITLE_SIGN_IN_SIGN_UP_LINK: "Registrarse",
        AUTH_PAGE_HEADER_SUBTITLE_SIGN_IN_END: "",

        AUTH_PAGE_HEADER_SUBTITLE_SIGN_UP_START: "¿Ya tienes una cuenta?",
        AUTH_PAGE_HEADER_SUBTITLE_SIGN_UP_SIGN_IN_LINK: "Iniciar sesión",
        AUTH_PAGE_HEADER_SUBTITLE_SIGN_UP_END: "",

        AUTH_PAGE_FOOTER_START: "Al continuar, aceptas nuestros ",
        AUTH_PAGE_FOOTER_TOS: "Términos de servicio",
        AUTH_PAGE_FOOTER_AND: " y ",
        AUTH_PAGE_FOOTER_PP: "Política de privacidad",
        AUTH_PAGE_FOOTER_END: "",

        DIVIDER_OR: "o",

        BRANDING_POWERED_BY_START: "Desarrollado por ",
        SOMETHING_WENT_WRONG_ERROR:
          "Algo salió mal. Por favor, inténtalo de nuevo.",
        SOMETHING_WENT_WRONG_ERROR_RELOAD:
          "Algo salió mal. Por favor, inténtalo más tarde o recarga la página.",
      },
    },
  },
});

SuperTokens.loadTranslation({});
console.log(
  "navigator.language",
  navigator.language,
  navigator.language.substring(0, 2).toLocaleLowerCase()
);
if (navigator.language.substring(0, 2).toLocaleLowerCase() == "en") {
  SuperTokens.changeLanguage("en");
} else {
  SuperTokens.changeLanguage("es");
}

// ==============================|| ROUTING RENDER ||============================== //
const superTokensRoutes = getSuperTokensRoutesForReactRouterDom(
  reactRouterDom,
  [PasswordlessPreBuiltUI]
);
const router = createBrowserRouter(
  [...superTokensRoutes.map((r) => r.props), MainRoutes, LoginRoutes],
  {
    basename: process.env.REACT_APP_BASE_NAME,
  }
);
export default router;
