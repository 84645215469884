import { memo, useMemo } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Button, Drawer, Stack, useMediaQuery } from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MenuList from "../MenuList";
import LogoSection from "../LogoSection";
import MiniDrawerStyled from "./MiniDrawerStyled";

import LAYOUT_CONST from "constant";
import useConfig from "hooks/useConfig";
import { drawerWidth } from "store/constant";

import { useDispatch, useSelector } from "store";
import { openDrawer } from "store/slices/menu";

import { signOut } from "supertokens-auth-react/recipe/session";
import { FormattedMessage } from "react-intl";
// ==============================|| SIDEBAR DRAWER ||============================== //

async function onLogout() {
  await signOut();
  window.location.href = "/login"; // or to wherever your logic page is
}

const Sidebar = () => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  const { layout, drawerType } = useConfig();

  const logo = useMemo(
    () => (
      <Box sx={{ display: "flex", p: 2 }}>
        <LogoSection />
      </Box>
    ),
    []
  );

  const drawerContent = (
    <>
      <MenuList />
      {layout === LAYOUT_CONST.VERTICAL_LAYOUT && drawerOpen}
      {layout === LAYOUT_CONST.VERTICAL_LAYOUT && drawerOpen && (
        <Stack direction="row" sx={{ mb: 2, pl: 3 }}>
          <Button
            onClick={onLogout}
            style={{
              fontFamily: "Hatch Sans",
              fontSize: "16px",
              color: "black",
              padding: "18px 36px",
            }}
          >
            <FormattedMessage id="menu_text_logout" />
          </Button>
        </Stack>
      )}
    </>
  );

  const drawerSX = {
    paddingLeft: drawerOpen ? "16px" : 0,
    paddingRight: drawerOpen ? "16px" : 0,
    marginTop: drawerOpen ? 0 : "20px",
  };

  const drawer = useMemo(
    () => (
      <>
        {matchDownMd ? (
          <Box sx={drawerSX}>{drawerContent}</Box>
        ) : (
          <PerfectScrollbar
            component="div"
            style={{
              height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
              ...drawerSX,
            }}
          >
            {drawerContent}
          </PerfectScrollbar>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [matchUpMd, drawerOpen, drawerType]
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: matchUpMd ? drawerWidth : "auto",
      }}
      aria-label="mailbox folders"
    >
      {matchDownMd ||
      (drawerType === LAYOUT_CONST.MINI_DRAWER && drawerOpen) ? (
        <Drawer
          variant={matchUpMd ? "persistent" : "temporary"}
          anchor="left"
          open={drawerOpen}
          onClose={() => dispatch(openDrawer(!drawerOpen))}
          sx={{
            "& .MuiDrawer-paper": {
              mt: matchDownMd ? 0 : 11,
              zIndex: 1099,
              width: drawerWidth,
              background: theme.palette.background.default,
              color: theme.palette.text.primary,
            },
          }}
          ModalProps={{ keepMounted: true }}
          color="inherit"
        >
          {matchDownMd && logo}
          {drawer}
        </Drawer>
      ) : (
        <MiniDrawerStyled variant="permanent" open={drawerOpen}>
          {logo}
          {drawer}
        </MiniDrawerStyled>
      )}
    </Box>
  );
};

export default memo(Sidebar);
